<template>
  <div
    class="bg-primary-50 d-flex flex-column align-items-center justify-content-center position-relative"
    style="height: 300px;border: 2px dashed rgb(176, 176, 176);"
    @dragenter.prevent="onDrag"
    @dragover.prevent="onDrag"
  >
    <div
      class="position-absolute bg-primary-200/90 align-items-center justify-content-center text-center"
      :class="isDrag ? 'd-flex' : 'd-none'"
      style="inset: 0;"
      @dragleave.prevent="onLeave"
      @drop.prevent="onDrop"
    >
      <h4>Lepaskan berkas disini</h4>
    </div>

    <div v-if="!file && !uploaded">
      <div class="text-center">
        <img src="@/assets/dashboard/icon/upload.png" alt="" />
        <div class="my-2">
          Tarik & letakakan berkas disini atau
        </div>
        <label :for="id" class="btn btn-outline-primary">
          Pilih Berkas
        </label>
      </div>
    </div>
    <div v-else>
      <div
        class="mb-3 text-center text-primary border border-primary p-3 rounded"
      >
        <i class="fa fa-lg fa-file-lines"></i>
        <div v-if="uploaded && !file">{{ uploaded }}</div>
        <div v-else>{{ file.name }}</div>
      </div>
      <div class="d-flex justify-content-center">
        <label :for="id" class="text-primary mr-3" role="button">
          <i class="fa fa-upload me-1"></i>
          Ganti berkas
        </label>
        <div role="button" @click="setFile(null)" class="text-danger">
          <i class="fa fa-trash me-1"></i>
          Hapus berkas
        </div>
      </div>
    </div>
    <input
      @change="selectFile"
      type="file"
      :id="id"
      class="form-control d-none"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDrag: false,
      file: null,
    };
  },
  props: ["uploaded", "id"],
  methods: {
    onDrag() {
      this.isDrag = true;
    },
    onLeave() {
      this.isDrag = false;
    },
    onDrop(e) {
      this.isDrag = false;
      const file = e.dataTransfer.files[0];
      this.setFile(file);
    },
    selectFile(e) {
      const file = e.target.files[0];
      this.setFile(file);
    },
    setFile(file) {
      this.file = file;
      this.$emit("select-file", file);
    },
  },
};
</script>
